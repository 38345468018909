<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-overlay :value="overlay2">
      <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white">
      </v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialogPilihPPH" max-width="600px" persistent>
      <v-card>
        <v-toolbar color="primary" dark>Choose PPH</v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete ref="role_id" v-model="pph" :items="itempph" item-text="text" item-value="value"
                  label="Pilih PPH" required></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogPilihPPH = false">
            Kembali
          </v-btn>
          <v-btn color="blue darken-1" text @click="pilihPPH">
            Pilih
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogdetailBupotPPh">
      <v-card flat>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Detail Bukti Potong PPh</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-row no-gutters class="px-5">
          <v-col :cols="4">
            <v-card ref="form" class="my-5">
              <v-card-text>
                <v-toolbar flat>
                  <v-toolbar-title>Dokumen Bukti Potong</v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-text-field v-model="dokumenBupotModal.bupot_number" label="Nomor Dok. BuktiPotong" filled readonly
                  class="mt-5"></v-text-field>
                <v-text-field v-model="dokumenBupotModal.bupot_date" label="Tanggal Bukti Potong" filled readonly>
                </v-text-field>
                <vuetify-money v-model="dokumenBupotModal.dpp_amount" label="DPP" v-bind:placeholder="placeholder"
                  readonly disabled filled v-bind:outlined="outlined" v-bind:clearable="clearable"
                  v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                <vuetify-money v-model="dokumenBupotModal.percentage" label="Tarif" v-bind:placeholder="placeholder"
                  readonly disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                  v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" />
                <vuetify-money v-model="dokumenBupotModal.pph_amount" label="PPh" v-bind:placeholder="placeholder"
                  readonly disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                  v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options2" />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col :cols="8">
            <v-card class="my-5 mx-2" outlined>
              <v-card-text>
                <v-toolbar flat>
                  <v-toolbar-title>Dokumen Kwitansi</v-toolbar-title>
                </v-toolbar>
                <v-divider></v-divider>
                <v-data-table :headers="headers" :items="dokumenKwtModal" class="elevation-1 my-5" dense>
                  <template v-slot:[`item.dpp_amount`]="{ item }">
                    {{ formatCurrency(item.dpp_amount) }}
                  </template>
                  <template v-slot:[`item.ppn_amount`]="{ item }">
                    {{ formatCurrency(item.ppn_amount) }}
                  </template>
                  <template v-slot:[`item.pph_amount2`]="{ item }">
                    {{ formatCurrency(item.pph_amount2) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card class="my-5" flat>
        <v-card-title>
          Data Customer
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-xs-center">
          <v-row no-gutters class="px-5" v-for="value in this.customerData" :key="value.id">
            <v-col><strong>Nama Customer: </strong> {{ value.customer_name }}</v-col>
            <!-- <v-col><strong>Email:</strong> {{ value.email }}</v-col> -->
          </v-row>
          <v-row no-gutters class="px-5" v-for="value2 in this.customerData" :key="'A' + value2.id">
            <v-col><strong>Active Status: </strong> {{ value2.active_flag }}</v-col>
            <v-col><strong>NPWP:</strong> {{ value2.npwp }}</v-col>
          </v-row>
          <v-row no-gutters class="px-5" v-for="value2 in this.customerData" :key="'B' + value2.id">
            <v-col><strong>Tipe Refund: </strong> {{ tipeRefund }}</v-col>
            <v-col><strong>Jenis PPH: </strong> {{ pphDisplay }}</v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>
      <v-toolbar flat>
        <v-toolbar-title>Input Bukti Potong</v-toolbar-title>
      </v-toolbar>
      <v-divider></v-divider>
      <v-btn color="success" dark class="mb-4 ml-4 mt-4" medium @click="showdialogInputBupot">
        Tambah Bukti Potong
      </v-btn>
      <v-btn color="error" dark class="mb-4 ml-4 mt-4" medium @click="deleteTempbuktipotong">
        Hapus Bukti Potong
      </v-btn>
      <v-divider></v-divider>
      <v-data-table v-model="selectedBupot" :headers="headersTemptable" :items="inquiryBupot" item-key="bupot_id"
        class="elevation-1" show-select>
        <template v-slot:[`item.bupot_number`]="{ item }">
          <!-- <router-link :to="{name: 'DetailBupot', params: { id: value }}"> -->
          <a @click="showDialog(item)" class="font-weight-bold">
            {{ item.bupot_number }}
          </a>
          <!-- <a @click="showPdf(item)" v-if="item.status === 'S'" class="font-weight-bold">
            {{ item.bupot_number }}
          </a>          
          <a @click="showDialog(item)" v-else-if="item.status === 'D'" class="font-weight-bold">
            {{ item.bupot_number }}
          </a>  
          <a @click="showPdf(item)" v-else-if="item.status === 'V'" class="font-weight-bold">
            {{ item.bupot_number }}
          </a>
          <a @click="showPdfReject(item)" v-else-if="item.status === 'R'" class="font-weight-bold">
            {{ item.bupot_number }}
          </a>      
          <a @click="showPdfCancel(item)" v-else-if="item.status === 'C'" class="font-weight-bold">
            {{ item.bupot_number }}
          </a>                         -->
          <!-- </router-link> -->
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <div class="font-weight-bold">
            {{ formatValidasi(item.status) }}
          </div>
        </template>
        <template v-slot:[`item.dpp_amount`]="{ item }">
          {{ formatCurrency(item.dpp_amount) }}
        </template>
        <template v-slot:[`item.pph_amount`]="{ item }">
          {{ formatCurrency(item.pph_amount) }}
        </template>
      </v-data-table>
      <v-dialog v-model="dialogInputBupot">
        <v-card v-if="skeletonRender === true">
          <v-toolbar color="primary" dark>Detail Bukti Potong PPh <v-spacer></v-spacer>
          </v-toolbar>
          <!-- <v-card-title>Detail Bukti Potong PPh
            <v-spacer></v-spacer>
            <v-btn color="success" dark class="mb-2 mr-1" @click="insertKwitansi">
              Simpan
            </v-btn>
          </v-card-title> -->
          <v-divider></v-divider>
          <v-row no-gutters class="px-5">
            <v-col :cols="4">
              <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="my-5">
                <v-skeleton-loader class="mx-auto my-2" type="heading"></v-skeleton-loader>
                <v-skeleton-loader class="mx-auto my-2" type="image,image"></v-skeleton-loader>
              </v-sheet>
            </v-col>
            <v-col :cols="8">
              <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="my-5 mx-2">
                <v-skeleton-loader class="mx-auto" type="table-heading, table-thead , table-row-divider@3, table-tfoot">
                </v-skeleton-loader>
              </v-sheet>
            </v-col>
          </v-row>
        </v-card>

        <v-card v-else>
          <v-overlay :value="overlay">
            <v-progress-circular :size="70" :width="7" :opacity="opacity" indeterminate color="white">
            </v-progress-circular>
          </v-overlay>
          <!-- <v-card-title>Detail Bukti Potong PPh
            <v-spacer></v-spacer>
            <v-btn color="success" dark class="mb-2 mr-1" @click="insertKwitansi">
              Simpan
            </v-btn>
          </v-card-title> -->
          <!-- <v-divider></v-divider> -->
          <v-toolbar color="primary" dark>Detail Bukti Potong PPh <v-spacer></v-spacer>
          </v-toolbar>
          <v-row no-gutters class="px-5">
            <v-col :cols="4">
              <v-card ref="form" class="my-5" outlined>
                <v-card-text>
                  <v-toolbar flat>
                    <v-toolbar-title>Dokumen Bukti Potong</v-toolbar-title>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-text-field min="0" onkeydown="return event.keyCode !== 187 && event.keyCode !== 189"
                    v-model="dokumenBupot.bupot_number" label="Nomor Dok. BuktiPotong" class="mt-5" outlined>
                  </v-text-field>
                  <v-text-field v-model="dokumenBupot.bupot_date" single-line label="Masukan Tanggal" outlined readonly>
                    <template v-slot:append-outer>
                      <date-picker v-model="dokumenBupot.bupot_date" />
                    </template>
                  </v-text-field>
                  <v-text-field v-model="dokumenBupot.percentage" readonly disabled label="Tarif" type="number"
                    @input="updatePPh()" outlined>
                  </v-text-field>
                  <vuetify-money v-model="dokumenBupot.dpp_amount" label="DPP" v-bind:placeholder="placeholder"
                    v-bind:readonly="readonly" disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options" :rules="[rules.required]"
                    class="mt-2 mb-0" />
                  <vuetify-money v-model="dokumenBupot.pph_amount" label="PPh" v-bind:placeholder="placeholder"
                    v-bind:readonly="readonly" disabled v-bind:outlined="outlined" v-bind:clearable="clearable"
                    v-bind:valueWhenIsEmpty="valueWhenIsEmpty" v-bind:options="options2" :rules="[rules.required]" />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="8">
              <v-card class="my-5 mx-2" outlined>
                <v-card-text>
                  <v-toolbar flat>
                    <v-toolbar-title>Dokumen Kwitansi</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialogShowkwt" max-width="1000px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="loadDatakwt">
                          + Kwitansi
                        </v-btn>

                      </template>
                      <v-card>
                        <v-toolbar class="mb-2" flat color="primary" dark>
                          <v-toolbar-title>Listing Kwitansi</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row class="ml-1">
                            <v-col>
                              <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                                hide-details></v-text-field>
                            </v-col>
                          </v-row>
                          <v-data-table v-model="simpan" :headers="headers" :items="listingKwt" :single-select="false"
                            :search="search" item-key="kwt_id" show-select class="elevation-1" :loading="loading"
                            loading-text="Fetching Data...">
                            <template v-slot:[`item.dpp_amount`]="{ item }">
                              {{ formatCurrency(item.dpp_amount) }}
                            </template>
                            <template v-slot:[`item.pph_amount2`]="{ item }">
                              {{ formatCurrency(item.pph_amount2) }}
                            </template>
                            <template v-slot:[`item.ppn_amount`]="{ item }">
                              {{ formatCurrency(item.ppn_amount) }}
                            </template>
                          </v-data-table>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="success" dark class="mb-2 mr-2" @click="save">
                            Tambah Ke Bupot
                          </v-btn>
                          <v-btn color="error" dark class="mb-2" @click="close">
                            Kembali
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-btn color="error" dark class="mb-2 ml-2" @click="deleteItem()">
                      - Kwitansi
                    </v-btn>
                    <v-dialog v-model="dialogInsertkwt" max-width="560px">
                      <v-card>
                        <v-card-title class="text-h5">Apakah anda ingin menambahkan kwitansi ini?</v-card-title>
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" @click="closedialogInsertkwt" text>Batal</v-btn>
                          <v-btn color="blue darken-1" text @click="saveInsert">Tambah</v-btn>
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                  <v-divider></v-divider>
                  <v-data-table v-model="simpan2" :headers="headers" :items="dokumenKwitansi" item-key="kwt_id" dense
                    class="elevation-1 my-5" show-select>
                    <template v-slot:[`item.dpp_amount`]="{ item }">
                      {{ formatCurrency(item.dpp_amount) }}
                    </template>
                    <template v-slot:[`item.pph_amount2`]="{ item }">
                      {{ formatCurrency(item.pph_amount2) }}
                    </template>
                    <template v-slot:[`item.ppn_amount`]="{ item }">
                      {{ formatCurrency(item.ppn_amount) }}
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" dark class="mb-2 mr-1" @click="insertKwitansi">
              Simpan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-card flat>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="success" dark class="mb-2 mx-1" @click="generateNorefund">
            Generate No Penyerahan
          </v-btn>

        </v-card-title>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
/* eslint-disable */
import axios from 'axios';
import { mapGetters } from 'vuex';
import DatePicker from "../../../components/DatePicker.vue";
import moment from "moment"
export default {
  data() {
    return {
      noUrut: 0,
      dialogdetailBupotPPh: false,
      cariData2: [],
      pph: "",
      pphDisplay: "",
      kolom: "",
      value2: "1234567.89",
      label: "Value",
      tipeRefund: "",
      placeholder: " ",
      readonly: false,
      disabled: false,
      outlined: true,
      clearable: true,
      search: '',
      valueWhenIsEmpty: "0",
      options: {
        locale: "pt-BR",
        prefix: "IDR",
        suffix: "",
        length: 11,
        precision: 0
      },
      options2: {
        locale: "pt-BR",
        prefix: "IDR",
        suffix: "",
        length: 11,
        precision: 2
      },
      properties: {
        hint: "my hint"
        // You can add other v-text-field properties, here.
      },
      counterPPH: 0,
      sumpphKwitansi: 0,
      value: null,
      dialogShowkwt: false,
      dialogInsertkwt: false,
      dialogInputBupot: false,
      dialogPilihPPH: true,
      singleSelect: false,
      skeletonRender: false,
      overlay: false,
      overlay2: false,
      opacity: 0.5,
      dokumenBupot: {},
      listingKwt: [],
      dokumenKwitansi: [],
      editedIndex: [],
      customerData: [],
      simpan: [],
      simpan2: [],
      idKwt: [],
      idKwt2: [],
      data: [],
      data2: [],
      loading: true,
      inquiryBupot: [],
      selectedBupot: [],
      dokumenBupotModal: {},
      dokumenKwtModal: [],
      itempph: [
        { text: 'PPH Ps 23', value: 'PPH 23' },
        { text: 'PPH Ps 4(2)', value: 'PPH 4 (2)' }
      ],
      headers: [
        { text: 'Nomor Kwitansi', value: 'kwt_number' },
        { text: 'Tanggal Kwitansi', value: 'kwt_date2' },
        { text: 'Jenis Kwitansi', value: 'kwt_type' },
        { text: 'DPP Kwitansi', value: 'dpp_amount' },
        { text: 'PPN Kwitansi', value: 'ppn_amount' },
        { text: 'PPh Kwitansi', value: 'pph_amount2' },
        { text: 'Tarif Kwitansi(%)', value: 'TARIF' },
      ],
      headersTemptable: [
        { text: 'Nomor Bukti Potong', value: 'bupot_number' },
        { text: 'Tanggal Bukti Potong', value: 'bupot_date' },
        { text: 'DPP Bukti Potong', value: 'dpp_amount' },
        { text: 'Tarif Bukti Potong', value: 'percentage' },
        { text: 'PPh', value: 'pph_amount' },
        { text: 'Jumlah Kwitansi', value: 'JumlahKwitansi' },
        { text: 'Kode Kwitansi', value: 'kode_kwitansi' },
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },

    }
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  mounted() {
    if (this.$session.get('flag_bupot') === 'N') {
      this.$swal.fire({
        title: 'Anda Tidak Memiliki Akses Untuk Mengajukan Bupot!',
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok',
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push('/trxpage');
        }
      })
    }
  },
  created() {
    axios({
      method: 'post',
      url: 'deleteAllTempBupotByCustomerId',
      data: {
        cust_id: this.$session.get('customer_id')
      },
    })
    axios({
      method: 'post',
      url: 'deleteCurrentEditDataonTempTable',
      data: {
        cust_id: this.$session.get('customer_id')
      },
    })
      .then(response => {
        // console.log(response)
        let uri2 = `gettempdata/${this.$session.get('customer_id')}`;
        axios.get(uri2).then(response => {
          this.inquiryBupot = response.data.data;
        });
      })
      .catch(error => {
        console.log(error.response)
      })
    this.tipeRefund = "Input Bupot"

    axios({
      method: 'post',
      url: 'getkwtterdaftar2',
      data: {
        customer_id: this.$session.get('customer_id'),
      },
    })
      .then((response) => {
        this.idKwt2 = response.data.data;
        this.idKwt2.forEach(item => {
          this.idKwt.push(item)
        })
      })
      .catch(error => {
        console.log(error.response)
      })
    // let uri = `getkwtterdaftar2`;
    //     axios.get(uri).then(response => {
    //         this.idKwt2 = response.data.data;
    //         this.idKwt2.forEach(item => {
    //             this.idKwt.push(item)
    //         })
    //     });      
    axios({
      method: 'post',
      url: 'getcustomerbyid',
      data: {
        cust_id: this.$session.get('customer_id')
      },
    })
      .then(response => {
        this.customerData = response.data.data;
      })
      .catch(error => {
        console.log(error.response)
      })
  },
  computed: {
    // result: function () {
    //   var num = (this.dokumenBupot.dpp_amount * this.dokumenBupot.percentage) / 100;
    //   return num;
    // },
    ...mapGetters({
      user: 'user',
    })

  },
  methods: {
    updatePPh() {
      this.dokumenBupot.pph_amount = (this.dokumenBupot.dpp_amount * this.dokumenBupot.percentage) / 100;
    },
    pilihPPH() {
      if (this.pph === '') {
        this.$swal.fire(
          'Warning!',
          'Harap memilih PPH!',
          'warning'
        )
      } else {
        this.counterPPH = this.counterPPH + 1;
        this.pphDisplay = this.pph;
        this.dialogPilihPPH = false;
      }
    },
    initialize() {
      let uri = `getkwtterdaftar2`;
      axios.get(uri).then(response => {
        this.idKwt2 = response.data.data;
        this.idKwt2.forEach(item => {
          this.idKwt.push(item)
        })
      });
      let uri2 = `gettempdata/${this.$session.get('customer_id')}`;
      axios.get(uri2).then(response => {
        this.inquiryBupot = response.data.data;
      });
      axios({
        method: 'post',
        url: 'getcustomerbyid',
        data: {
          cust_id: this.$session.get('customer_id')
        },
      })
        .then(response => {
          this.customerData = response.data.data;
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    insertKwitansi() {
      console.log(this.dokumenBupot.bupot_number.length)
      if (this.dokumenBupot.bupot_number.length !== 9 && this.dokumenBupot.bupot_number.length !== 10) {
        this.$swal.fire(
          'Warning!',
          'Nomor Bukti Potong Harus Di 9 sampai 10 karakter!',
          'warning'
        )
      } else {
        var kode_kwitansi;
        this.kolom = "";
        if (typeof this.dokumenBupot.bupot_number === 'undefined' || this.dokumenBupot.bupot_number === "") {
          this.kolom = this.kolom + "Nomor Bupot "
        }
        if (typeof this.dokumenBupot.bupot_date === 'undefined' || this.dokumenBupot.bupot_date === "") {
          this.kolom = this.kolom + "Tanggal Bupot "
        }
        if (typeof this.dokumenBupot.dpp_amount === 'undefined' || this.dokumenBupot.dpp_amount === "") {
          this.kolom = this.kolom + "DPP "
        }
        if (typeof this.dokumenBupot.percentage === 'undefined' || this.dokumenBupot.percentage === "") {
          this.kolom = this.kolom + "Tarif "
        }
        // alert(this.kolom);
        var sum = 0;
        // var flag_kwitansi ='';
        var tarif_kwitansi = '';
        if (typeof this.dokumenBupot.bupot_number === 'undefined' || this.dokumenBupot.bupot_number === "" || typeof this.dokumenBupot.bupot_date === 'undefined' || this.dokumenBupot.bupot_date === "" || typeof this.dokumenBupot.dpp_amount === 'undefined' || this.dokumenBupot.dpp_amount === "" || typeof this.dokumenBupot.percentage === 'undefined' || this.dokumenBupot.percentage === "" || typeof this.dokumenBupot.pph_amount === 'undefined' || this.dokumenBupot.pph_amount === "") {
          this.$swal.fire(
            'Warning!',
            this.kolom + 'Masih kosong!',
            'warning'
          )
        } else {
          this.dokumenKwitansi.forEach(item => {
            tarif_kwitansi = item.TARIF;
            kode_kwitansi = item.kwt_number.substr(0, 2);
            sum = sum + parseFloat(item.pph_amount2);
          })
          if (this.dokumenBupot.percentage == tarif_kwitansi) {
            this.sumpphKwitansi = sum;
            if (this.dokumenBupot.pph_amount != this.sumpphKwitansi) {
              this.$swal.fire(
                'Warning!',
                'Jumlah PPH tidak Sama dengan PPH Kwitansi yang diinput',
                'warning'
              )
            } else if (this.dokumenBupot.pph_amount == this.sumpphKwitansi) {
              this.$swal.fire({
                title: 'Apakah anda ingin menambahkan data ini?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Tambah'
              }).then((result) => {
                if (result.isConfirmed) {
                  this.overlay = true;
                  axios({
                    method: 'post',
                    url: 'inputtempinquiry',
                    data: {
                      user_id: this.user.id,
                      data_process: this.dokumenKwitansi,
                      data_bupot: this.dokumenBupot,
                      role_id: this.user.role_id,
                      customer_id: this.$session.get('customer_id'),
                      kode_kwitansi: kode_kwitansi
                    },
                  })
                    .then(() => {
                      this.overlay = false;
                      this.$swal.fire(
                        'Sukses!',
                        'Data berhasil di simpan!',
                        'success'
                      )
                      this.dialogInputBupot = false;
                      let uri2 = `gettempdata/${this.$session.get('customer_id')}`;
                      axios.get(uri2).then(response => {
                        this.inquiryBupot = response.data.data;
                      });
                      this.simpan2.splice(0);
                      this.simpan2 = [];
                    })
                    .catch(error => {
                      console.log(error.response)
                    })
                }
              })
            }
          } else {
            this.$swal.fire(
              'Warning!',
              'Jumlah Tarif Yang diinput tidak sama dengan Tarif Tipe Kwitansi',
              'warning'
            )
          }
        }
      }
    },
    close() {
      this.dialogShowkwt = false
    },
    save() {
      this.dialogInsertkwt = true;
    },
    searchData() {
      axios({
        method: 'post',
        url: 'searchdatakwt',
        data: {
          kwt_id: this.idKwt,
          kwt_number: this.cariData2.nomor_kwt,
          kwt_date: this.cariData2.tanggal_kwt
        },
      })
        .then(response => {
          this.listingKwt = response.data.data;
          // window.location.reload();
        })
        .catch(error => {
          console.log(error.response)

        })
    },
    loadDatakwt() {
      this.listingKwt = [];
      this.loading = true;
      this.simpan.splice(0);
      this.cariData2 = [];
      axios({
        method: 'post',
        url: 'getKwtStatusE',
        data: {
          customer_id: this.$session.get('customer_id')
        }
      })
        .then(response => {
          // console.log(response.data.data);
          response.data.data.forEach(item => {
            this.idKwt.push(item.kwt_id)
          })
          axios({
            method: 'post',
            url: 'listingkwt',
            data: {
              kwt_id: this.idKwt,
              customer_number: this.$session.get('customer_number'),
              customer_id: this.$session.get('customer_id'),
              tipe_pph: this.pphDisplay
            },
          })
            .then(response => {
              this.listingKwt = response.data.data;
              this.loading = false;
              // window.location.reload();
            })
            .catch(error => {
              console.log(error.response)

            })
          // let uri = `listingkwt`;
          // axios.post(uri, this.idKwt)
          //     .then(response => {
          //         this.listingKwt = response.data.data;
          //         this.loading=false;
          //     });                 
          // window.location.reload();
        })
        .catch(error => {
          console.log(error.response)

        })
    },
    saveInsert() {
      let counter = 0;
      let counterTipe = 0;
      var tarif = 0;
      let counter_error_tarif = 0;

      this.data = this.simpan;
      var sumDpp = 0;
      this.data.forEach(item => {
        if (counter > 0) {
          // let dataSebelum = this.data[counter - 1].kwt_number;
          // let dataSesudah = this.data[counter].kwt_number;
          let dataSebelum = this.data[counter - 1].TARIF;
          let dataSesudah = this.data[counter].TARIF;
          // console.log(this.data[counter].TARIF);
          // dataSebelum = dataSebelum.substr(0, 2);
          // dataSesudah = dataSesudah.substr(0, 2);
          if (dataSebelum !== dataSesudah) {
            counterTipe = counterTipe + 1;
          }
        }
        this.dokumenKwitansi.push({
          kwt_id: item.kwt_id,
          kwt_number: item.kwt_number,
          kwt_date2: item.kwt_date2,
          kwt_type: item.kwt_type,
          dpp_amount: item.dpp_amount,
          ppn_amount: item.ppn_amount,
          pph_amount2: item.pph_amount2,
          TARIF: item.TARIF
        })
        counter = counter + 1;
      })
      if (counterTipe > 0) {
        counter_error_tarif++;
        this.$swal.fire(
          'Warning!',
          'Tarif Kwitansi yang diinput harus sama!',
          'warning'
        )
        this.dokumenKwitansi = [];
      } else {
        /* eslint-disable */
        let counter2 = 0;
        let counterTipe2 = 0;
        this.dokumenKwitansi.forEach(item => {
          if (counter2 > 0) {
            let dataSebelum = this.dokumenKwitansi[counter2 - 1].TARIF;
            let dataSesudah = this.dokumenKwitansi[counter2].TARIF;
            // let dataSebelum = this.dokumenKwitansi[counter2 - 1].kwt_number;
            // let dataSesudah = this.dokumenKwitansi[counter2].kwt_number;
            // dataSebelum = dataSebelum.substr(0, 2);
            // dataSesudah = dataSesudah.substr(0, 2);
            if (dataSebelum !== dataSesudah) {
              counterTipe2 = counterTipe2 + 1;
            }
          }
          tarif = this.dokumenKwitansi[counter2].TARIF;
          counter2 = counter2 + 1;
          // console.log(item.kwt_number);
          sumDpp = sumDpp + item.dpp_amount;
        })
        if (counterTipe2 > 0) {
          counter_error_tarif++;
          this.$swal.fire(
            'Warning!',
            'Tarif Kwitansi yang diinput harus sama!',
            'warning'
          )
          let jumlahDatabaruYangdiinput = this.data.length;
          let jumlahDataExisting = this.dokumenKwitansi.length;
          let indexStartDelete = jumlahDataExisting - 1;
          let indexStartPoint = jumlahDataExisting - jumlahDatabaruYangdiinput - 1;
          for (let i = indexStartDelete; i > indexStartPoint; i--) {
            this.dokumenKwitansi.pop();
          }
        } else {
          this.data.forEach(item => {
            this.idKwt.push(item.kwt_id)
          })
          this.dokumenBupot.dpp_amount = sumDpp;
        }
      }
      this.dialogInsertkwt = false;
      this.dialogShowkwt = false;
      if (counter_error_tarif === 0) {
        this.dokumenBupot.percentage = tarif;
      }
      this.updatePPh();
    },
    closedialogInsertkwt() {
      this.dialogInsertkwt = false;
    },
    deleteItem() {
      var sumDpp = 0;
      for (var i = 0; i < this.simpan2.length; i++) {
        const index = this.dokumenKwitansi.indexOf(this.simpan2[i]);
        const index2 = this.idKwt.indexOf(this.simpan2[i].kwt_id);
        this.dokumenKwitansi.splice(index, 1);
        this.idKwt.splice(index2, 1);
      }
      this.simpan2.splice(0);
      this.dokumenKwitansi.forEach(item => {
        // console.log(item.kwt_number);
        sumDpp = sumDpp + item.dpp_amount;
      })
      if (this.dokumenKwitansi.length === 0) {
        // console.log("HABIS");
        this.dokumenBupot.percentage = 0;
      }
      this.dokumenBupot.dpp_amount = sumDpp;
      this.updatePPh();
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1)
      this.closeDelete()
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'IDR',
      });

      return formatter.format(value); /* $2,500.00 */
    },
    formatDate(value) {
      return moment(value).format("DD-MM-YYYY");
    },
    showdialogInputBupot() {
      if (this.counterPPH > 0) {
        this.dokumenBupot = Object.assign({}, this.defaultItem)
        this.dokumenKwitansi = [];
        this.skeletonRender = true;
        this.dialogInputBupot = true;
        axios({
          method: 'post',
          url: 'getkwtterdaftar2',
          data: {
            customer_id: this.$session.get('customer_id'),
          },
        })
          .then((response) => {
            this.idKwt2 = response.data.data;
            this.idKwt = this.idKwt2;
            this.skeletonRender = false;
          })
          .catch(error => {
            console.log(error.response)
          })
      } else {
        this.dialogPilihPPH = true;
      }

    },
    deleteTempbuktipotong() {
      if (this.selectedBupot.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Silahkan Pilih Bukti Potong yang mau dihapus!',
          'warning'
        )
      } else {
        this.$swal.fire({
          title: 'Apakah anda ingin menghapus data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Hapus',
          cancelButtonText: 'Batal'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'delete',
              url: 'deletetempinquiry',
              data: {
                data_process: this.selectedBupot,
              },
            })
              .then(() => {
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di hapus!',
                  'success'
                )
                let uri2 = `gettempdata/${this.$session.get('customer_id')}`;
                axios.get(uri2).then(response => {
                  this.inquiryBupot = response.data.data;
                });
                this.selectedBupot = [];
              })
              .catch(error => {
                console.log(error.response)
              })
          }
        })
        this.idKwt = [];
      }
    },
    showDialog(item) {
      let uri = `dtltempbupot/${item.bupot_id}`;
      axios.get(uri).then(response => {
        this.dokumenBupotModal = response.data.data;
      });
      let uri2 = `dtltempkwt/${item.bupot_id}/${this.$session.get('customer_id')}`;
      axios.get(uri2).then(response => {
        this.dokumenKwtModal = response.data.data;
      });
      this.dialogdetailBupotPPh = true
    },
    closeDialog() {
      this.dialogdetailBupotPPh = false
      this.dokumenBupotModal = {}
      this.dokumenKwtModal = []
    },
    generateNorefund() {
      if (this.inquiryBupot.length == 0) {
        this.$swal.fire(
          'Peringatan!',
          'Bukti Potong masih kosong!',
          'warning'
        )
      } else {
        var counter = 0;
        var counterTipe = 0;
        this.inquiryBupot.forEach(item => {
          if (counter > 0) {
            let dataSebelum = this.inquiryBupot[counter - 1].percentage;
            let dataSesudah = this.inquiryBupot[counter].percentage;
            // dataSebelum = dataSebelum.substr(0, 2);
            // dataSesudah = dataSesudah.substr(0, 2);
            if (dataSebelum !== dataSesudah) {
              counterTipe = counterTipe + 1;
            }
          }
          counter = counter + 1;
        })
        if (counterTipe > 0) {
          this.$swal.fire(
            'Peringatan!',
            'Tarif Kwitansi Yang diinput dalam 1 Nomor Refund Harus Seragam!',
            'warning'
          )
        } else {
          this.$swal.fire({
            title: 'Apakah anda ingin mengenerate nomor penyerahan bupot untuk data ini?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak'
          }).then((result) => {
            if (result.isConfirmed) {
              this.overlay2 = true;
              axios({
                method: 'post',
                url: 'generateNorefund',
                data: {
                  data_process: this.inquiryBupot,
                  customer_id: this.$session.get('customer_id')
                },
              })
                .then(response => {
                  this.noUrut = response.data.data;
                  var sumPPhrefund = 0;
                  var sumDpprefund = 0;
                  this.inquiryBupot.forEach(item => {
                    sumPPhrefund = sumPPhrefund + parseInt(item.pph_amount);
                  })
                  this.inquiryBupot.forEach(item => {
                    sumDpprefund = sumDpprefund + parseInt(item.dpp_amount);
                  })
                  axios({
                    method: 'post',
                    url: 'insertRefunddata',
                    data: {
                      data_process: this.inquiryBupot,
                      refund_number: this.noUrut,
                      user_id: this.user.id,
                      customer_id: this.$session.get('customer_id'),
                      role_id: this.user.role_id,
                      sum_pph: sumPPhrefund,
                      sum_dpp: sumDpprefund,
                      status_pph: this.pphDisplay,
                      status_refund: 'Input Bupot'
                    },
                  })
                    .then(() => {
                      this.overlay2 = false;
                      this.$swal.fire(
                        'Sukses!',
                        'Data berhasil di Simpan!',
                        'success'
                      )
                      let uri2 = `gettempdata/${this.$session.get('customer_id')}`;
                      axios.get(uri2).then(response => {
                        this.inquiryBupot = response.data.data;
                      });
                      this.$router.push('/trxpage');
                    })
                    .catch(error => {
                      console.log(error.response)
                    })
                })
                .catch(error => {
                  console.log(error.response)

                })
            }
          })
        }
      }
    },
    insertRefundData() {
    }
  },
  components: {
    DatePicker
  }
}

</script>
